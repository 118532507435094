import { useContext } from 'react';

import AuthContext from '../contexts/AuthContext';
import { isUserEnabled } from '../utils/permission';

const usePermission = () => {
  const { user } = useContext(AuthContext);

  return {
    canViewProject: user
      ? user.role === 'ORG_ADMIN' ||
        user.role === 'DASHBOARD_USER' ||
        user.role === 'FIELD_SUPERVISOR'
      : false,
    canCreateProject: !!(user && user.role === 'ORG_ADMIN'),
    canEditProject: !!(user && user.role === 'ORG_ADMIN'),
    canDeleteProject: !!(user && user.role === 'ORG_ADMIN'),
    canViewPhysicalProgress: !!(
      user && isUserEnabled(user, 'PHYSICAL_PROGRESS', 'READ')
    ),
    canCreatePhysicalProgress: !!(
      user && isUserEnabled(user, 'PHYSICAL_PROGRESS', 'CREATE')
    ),
    canUpdatePhysicalProgress: !!(
      user && isUserEnabled(user, 'PHYSICAL_PROGRESS', 'UPDATE')
    ),
    canDeletePhysicalProgress: !!(
      user && isUserEnabled(user, 'PHYSICAL_PROGRESS', 'DELETE')
    ),
    canManageProgress: !!(user && user.role === 'ORG_ADMIN') || !!(
      user && isUserEnabled(user, 'PROGRESS_VALIDATION', 'UPDATE')
    ),
    canViewFinancialProgress: !!(
      user && isUserEnabled(user, 'FINANCIAL_PROGRESS', 'READ')
    ),
    canCreateFinancialProgress: !!(
      user && isUserEnabled(user, 'FINANCIAL_PROGRESS', 'CREATE')
    ),
    canUpdateFinancialProgress: !!(
      user && isUserEnabled(user, 'FINANCIAL_PROGRESS', 'UPDATE')
    ),
    canDeleteFinancialProgress: !!(
      user && isUserEnabled(user, 'FINANCIAL_PROGRESS', 'DELETE')
    ),
    canViewDrawing: !!(
      user && isUserEnabled(user, 'DRAWING_MANAGEMENT', 'READ')
    ),
    canCreateDrawing: !!(
      user && isUserEnabled(user, 'DRAWING_MANAGEMENT', 'CREATE')
    ),
    canUpdateDrawing: !!(
      user && isUserEnabled(user, 'DRAWING_MANAGEMENT', 'UPDATE')
    ),
    canDeleteDrawing: !!(
      user && isUserEnabled(user, 'DRAWING_MANAGEMENT', 'DELETE')
    ),
    canViewIssue: !!(user && isUserEnabled(user, 'ISSUE_MANAGEMENT', 'READ')),
    canCreateIssue: !!(
      user && isUserEnabled(user, 'ISSUE_MANAGEMENT', 'CREATE')
    ),
    canUpdateIssue: !!(
      user && isUserEnabled(user, 'ISSUE_MANAGEMENT', 'UPDATE')
    ),
    canDeleteIssue: !!(
      user && isUserEnabled(user, 'ISSUE_MANAGEMENT', 'DELETE')
    ),
    canViewDrive: !!(user && isUserEnabled(user, 'DRIVE_MANAGEMENT', 'READ')),
    canCreateDrive: !!(
      user && isUserEnabled(user, 'DRIVE_MANAGEMENT', 'CREATE')
    ),
    canUpdateDrive: !!(
      user && isUserEnabled(user, 'DRIVE_MANAGEMENT', 'UPDATE')
    ),
    canDeleteDrive: !!(
      user && isUserEnabled(user, 'DRIVE_MANAGEMENT', 'DELETE')
    ),
    canViewMaterialManagement: !!(
      user && isUserEnabled(user, 'MATERIAL_MANAGEMENT', 'READ')
    ),
    canCreateMaterial: !!(
      user && isUserEnabled(user, 'MATERIAL_MANAGEMENT', 'CREATE')
    ),
    canUpdateMaterial: !!(
      user && isUserEnabled(user, 'MATERIAL_MANAGEMENT', 'UPDATE')
    ),
    canDeleteMaterial: !!(
      user && isUserEnabled(user, 'MATERIAL_MANAGEMENT', 'DELETE')
    ),
    canViewUser: !!(user && isUserEnabled(user, 'USER_MANAGEMENT', 'READ')),
    canCreateUser: !!(user && isUserEnabled(user, 'USER_MANAGEMENT', 'CREATE')),
    canUpdateUser: !!(user && isUserEnabled(user, 'USER_MANAGEMENT', 'UPDATE')),
    canDeleteUser: !!(user && isUserEnabled(user, 'USER_MANAGEMENT', 'DELETE')),
  };
};

export default usePermission;
