import gql from 'graphql-tag';

export const USER_BASIC_INFO_FRAGMENT = gql`
  fragment UserBasicInfoFragment on User {
    id
    name
    profileImage
    designation
    email
    emailVerified
    phone
    phoneVerified
    countryCode
    role
    organisation {
      id
      name
    }
    userType
  }
`;

export const USER_DETAIL_INFO_FRAGEMENT = gql`
  fragment UserDetailInfoFragment on User {
    ...UserBasicInfoFragment
    projects {
      id
      name
      photos
      startDate
      targetDateOfCompletion
    }
    permissions {
      scope
      module
    }
  }
  ${USER_BASIC_INFO_FRAGMENT}
`;

export const USERS_BASIC_INFO_QUERY = gql`
  query UsersBasicInfo(
    $organisationId: String
    $projectId: String
    $role: String
  ) {
    users(organisation: $organisationId, project: $projectId, role: $role) {
      ...UserBasicInfoFragment
    }
  }
  ${USER_BASIC_INFO_FRAGMENT}
`;

export const USER_DETAIL_INFO_QUERY = gql`
  query UserDetailInfo($userId: String!) {
    user(id: $userId) {
      ...UserDetailInfoFragment
    }
  }
  ${USER_DETAIL_INFO_FRAGEMENT}
`;

export const USERS_DETAIL_INFO_QUERY = gql`
  query UsersDetailInfo($organisationId: String) {
    users(organisation: $organisationId, sort: "createdAt") {
      ...UserDetailInfoFragment
    }
  }
  ${USER_DETAIL_INFO_FRAGEMENT}
`;

export const ASSIGN_PROJECTS_MUTATION = gql`
  mutation AssignProjects($userId: String!, $projectIds: [String!]!) {
    manageProjectsAssignee(
      manageProjectsAssigneeData: { user: $userId, projects: $projectIds }
    ) {
      success
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $name: String!
    $email: String
    $phone: String
    $countryCode: String
    $role: String!
    $designation: String
    $userType: String!
    $userId: String!
  ) {
    updateAccount(
      updateAccountData: {
        name: $name
        email: $email
        phone: $phone
        countryCode: $countryCode
        role: $role
        designation: $designation
        userType: $userType
      }
      id: $userId
    ) {
      ...UserDetailInfoFragment
    }
  }
  ${USER_DETAIL_INFO_FRAGEMENT}
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $name: String!
    $email: String
    $phone: String
    $countryCode: String
    $role: String!
    $designation: String
    $userType: String
  ) {
    createUser(
      createUserData: {
        name: $name
        email: $email
        phone: $phone
        countryCode: $countryCode
        role: $role
        designation: $designation
        password: "bocs@123"
        userType: $userType
        permissions: [
          {
            module: "USER_MANAGEMENT"
            scope: ["CREATE", "READ", "DELETE", "UPDATE"]
          }
          {
            module: "PHYSICAL_PROGRESS"
            scope: ["CREATE", "READ", "DELETE", "UPDATE"]
          }
          {
            module: "FINANCIAL_PROGRESS"
            scope: ["CREATE", "READ", "DELETE", "UPDATE"]
          }
          {
            module: "MATERIAL_MANAGEMENT"
            scope: ["CREATE", "READ", "DELETE", "UPDATE"]
          }
          {
            module: "ISSUE_MANAGEMENT"
            scope: ["CREATE", "READ", "DELETE", "UPDATE"]
          }
          {
            module: "DRAWING_MANAGEMENT"
            scope: ["CREATE", "READ", "DELETE", "UPDATE"]
          }
          {
            module: "DRIVE_MANAGEMENT"
            scope: ["CREATE", "READ", "DELETE", "UPDATE"]
          }
          {
            module: "MASTER_DASHBOARD"
            scope: ["CREATE", "READ", "DELETE", "UPDATE"]
          }
          {
            module: "PROGRESS_VALIDATION"
            scope: []
          }
        ]
      }
    ) {
      account {
        ...UserDetailInfoFragment
      }
    }
  }
  ${USER_DETAIL_INFO_FRAGEMENT}
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(id: $userId) {
      id
    }
  }
`;

export const USER_LEADERBOARD_SCORE_QUERY = gql`
  query UserLeaderboardScore($userId: String!) {
    userAdditionalInfo(id: $userId) {
      lastUpdatedAt
      photos {
        total
      }
      issues {
        total
      }
    }
  }
`;

export const LEADER_BOARD_QUERY = gql`
  query Leaderboard($projectId: String!) {
    leaderboard(project: $projectId) {
      id
      name
      profileImage
      designation
      email
      phone
      score
      lastUpdatedAt
    }
  }
`;

export const UPDATE_USER_PERMISSIONS_MUTATION = gql`
  mutation UpdateUserPermissions(
    $permissions: [PermissionInput!]!
    $userId: String!
  ) {
    updateAccount(
      updateAccountData: { permissions: $permissions }
      id: $userId
    ) {
      ...UserDetailInfoFragment
    }
  }
  ${USER_DETAIL_INFO_FRAGEMENT}
`;
