import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  cloneElement,
} from 'react';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { Form, Input, Button, message, Drawer, DatePicker, Select } from 'antd';
import { useMutation, useApolloClient } from 'react-apollo';
import TreeSelect, { TreeNode } from 'antd/lib/tree-select';
import { AntTreeNode } from 'antd/lib/tree';
import moment from 'moment';
import validator from 'validator';
import styled from 'styled-components';

import ImageUploader from '../ImageUploader';
import PlacesAutocomplete from '../PlacesAutocomplete';
import AuthContext from '../../contexts/AuthContext';
import {
  CreateProjectVariables,
  CreateProject as CreateProjectInterface,
} from '../../types/CreateProject';
import { CREATE_PROJECT_MUTATION, PROJECTS_QUERY } from '../../queries/project';
import { Groups, GroupsVariables } from '../../types/Groups';
import { GROUPS_QUERY } from '../../scenes/ProjectTreeDetailedView/queries';
import { Projects } from '../../types/Projects';
import countryCodes from '../../utils/countryCodes';

interface Props {
  trigger: JSX.Element;
  form: WrappedFormUtils;
  allowProjectGroupSelection?: boolean;
  onCreate?: () => void;
}

const CreateProject: React.FC<Props> = ({
  trigger,
  allowProjectGroupSelection,
  form: { getFieldDecorator, validateFields },
  onCreate,
}) => {
  const { user } = useContext(AuthContext);
  const organisationId = user ? user.organisation._id : '';

  const [drawerOpened, setDrawerOpened] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpened(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setDrawerOpened(false);
    if (onCreate) {
      onCreate();
    }
  }, [onCreate]);

  const client = useApolloClient();

  const [treeData, setTreeData] = useState<Array<TreeNode>>([]);

  const fetchGroups = useCallback(
    async (parentGroupId?: string) => {
      const { data, errors } = await client.query<Groups, GroupsVariables>({
        query: GROUPS_QUERY,
        variables: { parentGroupId },
      });
      if (data) {
        setTreeData((treeDataState) => {
          return [
            ...treeDataState,
            ...data.groups.map((group) => ({
              id: group.id,
              key: group.id,
              value: group.id,
              selectable: true,
              isLeaf: group.children && group.children.length === 0,
              pId: parentGroupId,
              title: group.name,
            })),
          ];
        });
      } else if (errors) {
        message.error(errors[0].message);
      }
    },
    [client],
  );

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const handleLoadData = useCallback(
    async (node: AntTreeNode) => {
      const groupId = (node as AntTreeNode).props.id as string;
      await fetchGroups(groupId);
    },
    [fetchGroups],
  );

  const [createProjectMutation, { loading }] = useMutation<
    CreateProjectInterface,
    CreateProjectVariables
  >(CREATE_PROJECT_MUTATION, {
    update: !allowProjectGroupSelection
      ? (cache, { data }) => {
          if (data) {
            let projectsData;
            try {
              projectsData = cache.readQuery<Projects>({
                query: PROJECTS_QUERY,
              });
            } catch (cacheReadError) {
              projectsData = undefined;
            }

            if (projectsData) {
              cache.writeQuery<Projects>({
                query: PROJECTS_QUERY,
                data: {
                  projects: [...projectsData.projects, data.createProject],
                },
              });
            }
          }
        }
      : undefined,
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: (data) => {
      message.success(
        `${data.createProject.name} project created successfully`,
      );
      handleDrawerClose();
      if (allowProjectGroupSelection) {
        window.location.reload(true);
      }
    },
  });

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          createProjectMutation({
            variables: {
              name: values.name,
              organisationId,
              groupIds: values.groupIds,
              thumbnail: values.photo ? [values.photo[0]] : [],
              location: values.location
                ? [values.location.longitude, values.location.latitude]
                : undefined,
              schemeName: values.schemeName,
              contracterOrCompanyName: values.contracterOrCompanyName,
              agreementNo: values.agreementNo,
              agreementDate: (values.agreementDate as moment.Moment).valueOf(),
              workOrder: values.workOrder ? values.workOrder[0] : [],
              startDateAsPerAgreement: (
                values.agreementDates[0] as moment.Moment
              ).valueOf(),
              finishDateAsPerAgreement: (
                values.agreementDates[1] as moment.Moment
              ).valueOf(),
              contactPersonName: values.contactPersonName,
              contactPersonCountryCode: values.contactPersonCountryCode,
              contactPersonPhone: values.contactPersonPhone,
              contactPersonEmail: values.contactPersonEmail,
              district: values.district,
              block: values.block,
              village: values.village,
            },
          });
        }
      });
    },
    [createProjectMutation, validateFields, organisationId],
  );

  return (
    <>
      {cloneElement(trigger, {
        onClick: handleDrawerOpen,
      })}
      <Drawer
        title="Create Project"
        width={320}
        visible={drawerOpened}
        onClose={handleDrawerClose}
        destroyOnClose
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Project Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Name is required',
                },
              ],
            })(<Input placeholder="Project Name" />)}
          </Form.Item>

          <Form.Item label="Project Location">
            {getFieldDecorator('location', {
              getValueFromEvent: ({ latitude, longitude }) => {
                return { longitude, latitude };
              },
            })(<PlacesAutocomplete />)}
          </Form.Item>

          <Form.Item label="Name of Scheme">
            {getFieldDecorator('schemeName', {
              rules: [
                {
                  required: true,
                  message: 'Scheme name is required',
                },
              ],
            })(<Input placeholder="Scheme Name" />)}
          </Form.Item>

          <Form.Item label="Contractor/ Firm/ Company Name">
            {getFieldDecorator('contracterOrCompanyName', {
              rules: [
                {
                  required: true,
                  message: 'Contractor/ Firm/ Company Name is required',
                },
              ],
            })(<Input placeholder="Contractor/ Firm/ Company Name" />)}
          </Form.Item>

          <Form.Item label="Agreement number">
            {getFieldDecorator('agreementNo', {
              rules: [
                {
                  required: true,
                  message: 'Agreement number is required',
                },
              ],
            })(<Input placeholder="Agreement number" />)}
          </Form.Item>

          <Form.Item label="Agreement date">
            {getFieldDecorator('agreementDate', {
              rules: [
                {
                  required: true,
                  message: 'Agreement date is required',
                },
              ],
            })(<DatePicker placeholder="Agreement date" />)}
          </Form.Item>

          <Form.Item label="Dates as per agreement">
            {getFieldDecorator('agreementDates', {
              rules: [
                {
                  type: 'array',
                  required: true,
                  message: 'Start Date & Finish Date are required',
                },
              ],
            })(
              <DatePicker.RangePicker
                placeholder={['Start Date', 'Finish Date']}
              />,
            )}
          </Form.Item>

          <Form.Item label="Name of contact person">
            {getFieldDecorator('contactPersonName', {
              rules: [
                {
                  required: true,
                  message: 'Name of contact person is required',
                },
              ],
            })(<Input placeholder="Name of contact person" />)}
          </Form.Item>

          <div className="ant-form-item-label">
            <label>Mobile Number</label>
          </div>
          <PhoneInputContainer className="flex">
            <Form.Item>
              {getFieldDecorator('contactPersonCountryCode')(
                <Select
                  placeholder="Country Code"
                  style={{ minWidth: 120, maxWidth: 120 }}
                  showSearch
                  filterOption={(input: string, option: any) => {
                    return (
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {countryCodes.map((code) => (
                    <Select.Option key={code.dialCode}>
                      {`${code.dialCode} - ${code.name}`}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('contactPersonPhone')(
                <Input placeholder="Mobile Number" />,
              )}
            </Form.Item>
          </PhoneInputContainer>

          <Form.Item label="Email ID">
            {getFieldDecorator('contactPersonEmail', {
              rules: [
                {
                  validator: async (rules: any, value: string) => {
                    if (value && !validator.isEmail(value)) {
                      throw new Error('Email is not valid');
                    }
                  },
                  message: 'Email of contact person is required',
                },
              ],
            })(<Input placeholder="Email of contact person" />)}
          </Form.Item>

          <Form.Item label="District">
            {getFieldDecorator('district', {
              rules: [
                {
                  required: true,
                  message: 'District is required',
                },
              ],
            })(<Input placeholder="District" />)}
          </Form.Item>

          <Form.Item label="Block">
            {getFieldDecorator('block', {
              rules: [
                {
                  required: true,
                  message: 'Block is required',
                },
              ],
            })(<Input placeholder="Block" />)}
          </Form.Item>

          <Form.Item label="Village">
            {getFieldDecorator('village', {
              rules: [
                {
                  required: true,
                  message: 'Village is required',
                },
              ],
            })(<Input placeholder="Village" />)}
          </Form.Item>

          <Form.Item label="Project Thumbnail">
            {getFieldDecorator('photo')(
              <ImageUploader
                multiple={false}
                accept="image/png,image/jpg,image/jpeg,image/bmp"
              />,
            )}
          </Form.Item>

          <Form.Item label="Attach work order">
            {getFieldDecorator('workOrder')(
              <ImageUploader
                multiple={false}
                accept="image/png,image/jpg,image/jpeg,image/bmp,application/pdf"
              />,
            )}
          </Form.Item>

          {allowProjectGroupSelection ? (
            <Form.Item label="Associate Projects">
              {getFieldDecorator('groupIds')(
                <TreeSelect
                  placeholder="Select Group"
                  treeData={treeData}
                  treeDataSimpleMode={{ pId: 'pId', id: 'id' }}
                  loadData={handleLoadData}
                  multiple
                />,
              )}
            </Form.Item>
          ) : null}

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

const PhoneInputContainer = styled.div`
  & .ant-select-selection {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  & input {
    border-left-color: transparent;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export default Form.create<Props>({ name: 'createProject' })(CreateProject);
