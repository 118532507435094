import gql from 'graphql-tag';

export const PROJECT_BASIC_INFO_FRAGMENT = gql`
  fragment ProjectBasicInfoFragment on Project {
    id
    name
    startDate
    targetDateOfCompletion
    duration
    durationLeft
    totalCost
    metaStats {
      categoryCount
      projectElementCount
      driveSize
    }
    billCount
    issueCount {
      resolved
      unresolved
    }
    drawingCount
    photos
    location {
      type
      coordinates
    }
    cummProgressPercentage
    cummPlannedProgressPercentage
    cummFinancialProgress
    cummFinancialProgressPercentage
    leadPercentage
    status
    lastUpdatedAt
    projectedTDC
    projectedExtraDays
    projectLength
  }
`;

export const PROJECTS_FOR_ORGANISATION_QUERY = gql`
  query ProjectsForOrganisation($organisationId: String!) {
    projects(organisation: $organisationId) {
      ...ProjectBasicInfoFragment
    }
  }
  ${PROJECT_BASIC_INFO_FRAGMENT}
`;

export const PROJECTS_QUERY = gql`
  query Projects {
    projects {
      ...ProjectBasicInfoFragment
    }
  }
  ${PROJECT_BASIC_INFO_FRAGMENT}
`;

export const PROJECT_BASIC_INFO_QUERY = gql`
  query ProjectBasicInfo($projectId: String!) {
    project(id: $projectId) {
      ...ProjectBasicInfoFragment
    }
  }
  ${PROJECT_BASIC_INFO_FRAGMENT}
`;

export const PROJECT_WEATHER_QUERY = gql`
  query ProjectWeather($projectId: String!) {
    project(id: $projectId) {
      id
      weather {
        main {
          temp
          temp_max
          temp_min
        }
        base
        weather {
          description
          main
          icon
          id
        }
      }
    }
  }
`;

export const PROJECT_PROGRESS_S_CURVE = gql`
  query ProjectProgressSCurve($projectId: String!) {
    projectTrend(id: $projectId, sCurve: true, frequency: "monthly") {
      tick
      cummActualProgressPercentage
      cummPlannedProgressPercentage
      cummActualFinancialProgressPercentage
      cummExpectedFinancialProgressPercentage
      cummPlannedFinancialProgressPercentage
    }
  }
`;

export const PROJECT_PROGRESS_INFO_FRAGMENT = gql`
  fragment ProjectProgressInfoFragment on ProjectAdditionalInfo {
    geoCollections {
      type
      features {
        id
        type
        geometry {
          type
          coordinates
        }
        properties
      }
    }
  }
`;

export const PROJECT_PROGRESS_INFO_QUERY = gql`
  query ProjectProgressInfo($projectId: String!) {
    projectAdditionalInfo(id: $projectId) {
      ...ProjectProgressInfoFragment
    }
  }
  ${PROJECT_PROGRESS_INFO_FRAGMENT}
`;

export const ASSOCIATE_PROJECTS_MUTATION = gql`
  mutation AssociateProjects($projectIds: [String!], $groupId: String!) {
    updateGroup(updateGroupData: { projects: $projectIds }, id: $groupId) {
      id
      name
    }
  }
`;

// export const UPDATE_PROJECT = gql`
//   mutation UpdateProject(
//     $projectId: String!
//     $name: String
//     $thumbnail: [String!]
//     $location: [Float!]
//     $projectLength: Float
//     $schemeName: String
//     $contracterOrCompanyName: String
//     $agreementNo: String
//     $agreementDate: Float
//     $workOrder: String
//     $startDateAsPerAgreement: Float
//     $finishDateAsPerAgreement: Float
//     $contactPersonName: String
//     $contactPersonCountryCode: String
//     $contactPersonPhone: String
//     $contactPersonEmail: String
//     $district: String
//     $block: String
//     $village: String
//   ) {
//     updateProject(
//       updateProjectData: {
//         name: $name
//         photos: $thumbnail
//         location: $location
//         projectLength: $projectLength
//         schemeName: $schemeName
//         contracterOrCompanyName: $contracterOrCompanyName
//         agreementNo: $agreementNo
//         agreementDate: $agreementDate
//         workOrder: $workOrder
//         startDateAsPerAgreement: $startDateAsPerAgreement
//         finishDateAsPerAgreement: $finishDateAsPerAgreement
//         contactPersonName: $contactPersonName
//         contactPersonCountryCode: $contactPersonCountryCode
//         contactPersonPhone: $contactPersonPhone
//         contactPersonEmail: $contactPersonEmail
//         district: $district
//         block: $block
//         village: $village
//       }
//       id: $projectId
//     ) {
//       ...ProjectBasicInfoFragment
//     }
//   }
//   ${PROJECT_BASIC_INFO_FRAGMENT}
// `;
export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $projectId: String!
    $name: String
    $thumbnail: [String!]
    $location: [Float!]
  ) {
    updateProject(
      updateProjectData: {
        name: $name
        photos: $thumbnail
        location: $location
      }
      id: $projectId
    ) {
      ...ProjectBasicInfoFragment
    }
  }
  ${PROJECT_BASIC_INFO_FRAGMENT}
`;

export const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject(
    $name: String!
    $organisationId: String!
    $projectCategoryId: String
    $thumbnail: [String!]
    $location: [Float!]
    $groupIds: [String!]
    $schemeName: String!
    $contracterOrCompanyName: String!
    $agreementNo: String!
    $agreementDate: Float!
    $workOrder: String!
    $startDateAsPerAgreement: Float!
    $finishDateAsPerAgreement: Float!
    $contactPersonName: String!
    $contactPersonCountryCode: String!
    $contactPersonPhone: String!
    $contactPersonEmail: String!
    $district: String!
    $block: String!
    $village: String!
  ) {
    createProject(
      createProjectData: {
        name: $name
        organisation: $organisationId
        projectCategory: $projectCategoryId
        photos: $thumbnail
        location: $location
        groups: $groupIds
        schemeName: $schemeName
        contracterOrCompanyName: $contracterOrCompanyName
        agreementNo: $agreementNo
        agreementDate: $agreementDate
        workOrder: $workOrder
        startDateAsPerAgreement: $startDateAsPerAgreement
        finishDateAsPerAgreement: $finishDateAsPerAgreement
        contactPersonName: $contactPersonName
        contactPersonCountryCode: $contactPersonCountryCode
        contactPersonPhone: $contactPersonPhone
        contactPersonEmail: $contactPersonEmail
        district: $district
        block: $block
        village: $village
      }
    ) {
      ...ProjectBasicInfoFragment
    }
  }
  ${PROJECT_BASIC_INFO_FRAGMENT}
`;
