import React from 'react';
import { useQuery } from 'react-apollo';
import { Result, Icon, Divider } from 'antd';
import { capitalize } from 'lodash-es';
import { Link } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import { USER_DETAIL_INFO_QUERY } from '../../queries/user';
import {
  UserDetailInfo,
  UserDetailInfoVariables,
} from '../../types/UserDetailInfo';
import Skeleton from '../Skeleton';
import UserAvatar from '../UserAvatar';
import LeaderBoardScore from './components/LeaderboardScore';
import ProgressUpdates from './components/ProgressUpdates';

interface Props {
  userId: string;
  onlyBasicInfo?: boolean;
  // TODO: Find better way to inject dropDownMenu into UserDetail
  dropDownMenu?: JSX.Element;
}

const UserDetail: React.FC<Props> = ({
  userId,
  dropDownMenu,
  onlyBasicInfo,
}) => {
  const { loading, error, data } = useQuery<
    UserDetailInfo,
    UserDetailInfoVariables
  >(USER_DETAIL_INFO_QUERY, {
    variables: {
      userId,
    },
  });

  if (loading) {
    return (
      <>
        <Skeleton title avatar paragraph={{ rows: 2 }} />
        <Skeleton title paragraph={{ rows: 1 }} />
        <Skeleton title paragraph={{ rows: 1 }} />
      </>
    );
  }

  if (error) {
    return <Result status="warning" subTitle={error.message} />;
  }

  if (data) {
    const { user } = data;

    const userProjects = user.projects || [];

    return (
      <>
        <div className="flex items-center mb-4">
          <span className="mr-4">
            <UserAvatar userId={user.id} shape="square" size="large" />
          </span>
          <div>
            <div className="text-sm text-heading-color">{user.name}</div>
            <div className="text-xs text-caption-color">
              <span className="mr-1">
                {user.role
                  .split('_')
                  .map((val) => capitalize(val))
                  .join(' ')}
              </span>
              {user.designation ? <span>({user.designation})</span> : null}
            </div>
          </div>
          {dropDownMenu ? (
            <>
              <div className="flex-1" />
              {dropDownMenu}
            </>
          ) : null}
        </div>
        {onlyBasicInfo ? null : (
          <>
            {user.phone ? (
              <a href={`tel:+${user.phone}`} className="block mb-1">
                <div className="flex items-center text-sm">
                  <span className="mr-2 text-primary-color">
                    <Icon type="phone" />
                  </span>
                  <span>+{user.phone}</span>
                </div>
              </a>
            ) : null}
            {user.phone ? (
              <a href={`sms:+${user.phone}`} className="block mb-1">
                <div className="flex items-center text-sm">
                  <span className="mr-2 text-primary-color">
                    <Icon type="message" />
                  </span>
                  <span>+{user.phone}</span>
                </div>
              </a>
            ) : null}
            {user.email ? (
              <a
                href={`mailto:${user.email}`}
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <div className="flex items-center text-sm">
                  <span className="mr-2 text-primary-color">
                    <Icon type="mail" />
                  </span>
                  <span className="text-sm">{user.email}</span>
                </div>
              </a>
            ) : null}
            <Divider />{' '}
            <div className="mb-4 text-xs font-medium tracking-wider uppercase text-primary-color">
              Projects Assigned
            </div>
            {userProjects.map((project, index) => (
              <Link
                key={project.id}
                to={`/projects/${project.id}/dashboard`}
                className={clsx(
                  'block',
                  index !== userProjects.length - 1 ? 'mb-4' : undefined,
                )}
              >
                <div className="flex items-center">
                  {project.photos && project.photos.length > 0 ? (
                    <img
                      className="object-cover w-12 h-12 mr-4 rounded"
                      src={project.photos[0]}
                      alt={project.name}
                    />
                  ) : (
                    <div className="flex items-center justify-center w-12 h-12 mr-4 rounded bg-light-primary-color">
                      <img
                        className="h-8"
                        src={require('../../images/logo.svg')}
                        alt="BOCS"
                      />
                    </div>
                  )}
                  <div>
                    <div className="font-medium text-heading-color">
                      {project.name}
                    </div>
                    {project.startDate && project.targetDateOfCompletion ? (
                      <div className="text-sm">
                        {moment(project.startDate).format('YYYY')} -{' '}
                        {moment(project.targetDateOfCompletion).format('YYYY')}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Link>
            ))}
            {user.role === 'FIELD_SUPERVISOR' ? (
              <>
                <Divider />
                <LeaderBoardScore userId={userId} />
                <Divider />
                <ProgressUpdates userId={userId} />
              </>
            ) : null}
          </>
        )}
      </>
    );
  }

  return null;
};

export default UserDetail;
